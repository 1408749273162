import {
  mdiCalendarOutline,
} from '@mdi/js'

export default [
  {
    subheader: 'CATALOGS',
    resource: 'REGISTRO',
    action: 'read',
  },
  {
    title: 'Markets',
    icon: mdiCalendarOutline,
    to: 'catalogos-ctmarkets-list',
    resource: 'REGISTRO',
    action: 'read',
  },
  {
    title: 'Features',
    icon: mdiCalendarOutline,
    to: 'catalogos-ctfeatures-list',
    resource: 'REGISTRO',
    action: 'read',
  },
  {
    title: 'Properties Type',
    icon: mdiCalendarOutline,
    to: 'catalogos-ctpropertytype-list',
    resource: 'REGISTRO',
    action: 'read',
  },
]
