import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import { Icon } from 'leaflet'
import VueFriendlyIframe from 'vue-friendly-iframe'
import VueLuxon from 'vue-luxon'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import 'leaflet/dist/leaflet.css'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

Vue.directive('uppercase', {
  bind(el, _, vnode) {
    el.addEventListener('input', e => {
      e.target.value = e.target.value.toUpperCase()
      vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
    })
  },
})

Vue.filter('format_number', value => parseInt(value, 10).toLocaleString())
Vue.filter('round_number', value => Math.round(value).toLocaleString())

Vue.config.productionTip = false
Vue.use(VueFriendlyIframe)
Vue.use(VueLuxon)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
