import {
  mdiCalendarOutline,
} from '@mdi/js'

export default [
  {
    subheader: 'MAIN',
    resource: 'REGISTRO',
    action: 'read',
  },
  {
    title: 'List Test',
    icon: mdiCalendarOutline,
    resource: 'REGISTRO',
    action: 'read',
  },
]
