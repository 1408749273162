const apps = [
  // KEY SECTION PARTS
  //
  //* ——— MAIN SECTION ——— *
  //
  //* ——— Prop Comp ——— *
  //
  {
    path: '/apps/propertycomp/list',
    name: 'apps-propertycomp-list',
    component: () => import('@/views/apps/propertycomp/PropCompLista.vue'),
    meta: {
      layout: 'content',
      resource: 'REGISTRO',
      action: 'read',
    },
  },
  {
    path: '/apps/propertycomp/add',
    name: 'apps-propertycomp-add',
    component: () => import('@/views/apps/propertycomp/PropCompAdd.vue'),
    meta: {
      layout: 'content',
      resource: 'REGISTRO',
      action: 'read',
    },
  },
  {
    path: '/apps/propertycomp/edit',
    name: 'apps-propertycomp-edit',
    component: () => import('@/views/apps/propertycomp/PropCompEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'REGISTRO',
      action: 'read',
    },
  },
  {
    path: '/apps/propertycomp/adjustments',
    name: 'apps-propertycomp-adjustment',
    component: () => import('@/views/apps/propertycomp/adjustments/PropertyAdjustments.vue'),
    meta: {
      layout: 'content',
      resource: 'REGISTRO',
      action: 'read',
    },
  },

  //* ——— Prop List ——— *
  //
  {
    path: '/apps/propertylist/list',
    name: 'apps-property-list',
    component: () => import('@/views/apps/propertylist/PropertyLista.vue'),
    meta: {
      layout: 'content',
      resource: 'REGISTRO',
      action: 'read',
    },
  },

  //
  //* ——— CATALOGS SECTION ——— *
  //
  //* ——— Markets ——— *
  //
  {
    path: '/catalogos/ctmarkets/list',
    name: 'catalogos-ctmarkets-list',
    component: () => import('@/views/catalogos/ctmarkets/MarketsList.vue'),
    meta: {
      layout: 'content',
      resource: 'REGISTRO',
      action: 'read',
    },
  },
  {
    path: '/catalogos/ctmarkets/add',
    name: 'catalogos-ctmarkets-add',
    component: () => import('@/views/catalogos/ctmarkets/MarketsAdd.vue'),
    meta: {
      layout: 'content',
      resource: 'REGISTRO',
      action: 'read',
    },
  },
  {
    path: '/catalogos/ctmarkets/edit',
    name: 'catalogos-ctmarkets-edit',
    component: () => import('@/views/catalogos/ctmarkets/MarketsEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'REGISTRO',
      action: 'read',
    },
  },

  {
    path: '/catalogos/ctfeatures/list',
    name: 'catalogos-ctfeatures-list',
    component: () => import('@/views/catalogos/ctfeatures/FeaturesList.vue'),
    meta: {
      layout: 'content',
      resource: 'REGISTRO',
      action: 'read',
    },
  },
  {
    path: '/catalogos/ctfeatures/add',
    name: 'catalogos-ctfeatures-add',
    component: () => import('@/views/catalogos/ctfeatures/FeaturesAdd.vue'),
    meta: {
      layout: 'content',
      resource: 'REGISTRO',
      action: 'read',
    },
  },
  {
    path: '/catalogos/ctfeatures/edit',
    name: 'catalogos-ctfeatures-edit',
    component: () => import('@/views/catalogos/ctfeatures/FeaturesEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'REGISTRO',
      action: 'read',
    },
  },

  {
    path: '/catalogos/ctpropertytype/list',
    name: 'catalogos-ctpropertytype-list',
    component: () => import('@/views/catalogos/ctpropertytype/PropertyTypeList.vue'),
    meta: {
      layout: 'content',
      resource: 'REGISTRO',
      action: 'read',
    },
  },
  {
    path: '/catalogos/ctpropertytype/edit',
    name: 'catalogos-ctpropertytype-edit',
    component: () => import('@/views/catalogos/ctpropertytype/PropertyTypeEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'REGISTRO',
      action: 'read',
    },
  },

]

export default apps
