import {
  mdiCalendarOutline,
} from '@mdi/js'

export default [
  {
    subheader: 'MAIN',
    resource: 'REGISTRO',
    action: 'read',
  },
  {
    title: 'Property List',
    icon: mdiCalendarOutline,
    to: 'apps-property-list',
    resource: 'REGISTRO',
    action: 'read',
  },
  {
    title: 'Property Comparison',
    icon: mdiCalendarOutline,
    to: 'apps-propertycomp-list',
    resource: 'REGISTRO',
    action: 'read',
  },
]
