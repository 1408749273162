// eslint-disable-next-line object-curly-newline
import { mdiHomeOutline } from '@mdi/js'

export default [
  {
    title: 'Dashboards',
    icon: mdiHomeOutline,
    children: [
      {
        title: 'List Test',
        resource: 'REGISTRO',
        action: 'read',
      },
    ],
  },
]
